<template>
    <div>
        <vs-dialog
            v-model="isOpen"
            width="80vw"
            scroll
            :loading="isLoading"
            :prevent-close="isLoading"
            @close="onClose"
        >
            <template #header>
                <div
                    v-if="isOpenImageCreator"
                    class="w-100 d-flex justify-content-between"
                >
                    <h3>Crear nueva imagen</h3>
                    <div class="d-flex">
                        <vs-tooltip
                            v-if="
                                enabledCreate &&
                                $ability.hasScope('image:Create')
                            "
                            border
                            bottom
                        >
                            <vs-button
                                circle
                                icon
                                floating
                                @click="isOpenImageCreator = false"
                            >
                                🔙
                            </vs-button>
                            <template #tooltip>
                                <span>Lista de imágenes</span>
                            </template>
                        </vs-tooltip>
                    </div>
                </div>
                <div v-else>
                    <div class="w-100 d-flex justify-content-between">
                        <h3>Seleccione una imagen</h3>
                        <div class="d-flex">
                            <vs-button
                                :disabled="!selectedImage"
                                icon
                                circle
                                @click="pushNewImage"
                            >
                                ✔️
                            </vs-button>
                            <vs-tooltip
                                v-if="
                                    enabledCreate &&
                                    $ability.hasScope('image:Create')
                                "
                                border
                                bottom
                            >
                                <vs-button
                                    circle
                                    icon
                                    floating
                                    @click="isOpenImageCreator = true"
                                >
                                    ➕
                                </vs-button>
                                <template #tooltip>
                                    <span> Cargar nueva imagen </span>
                                </template>
                            </vs-tooltip>
                        </div>
                    </div>

                    <div v-show="message" class="my-4">
                        <p>{{ message }}</p>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <div v-if="isOpenImageCreator">
                    <image-creator
                        @loading="onLoading"
                        @created="selectionUploadImage"
                    />
                </div>
                <div v-else>
                    <div
                        class="d-flex justify-content-between flex-column flex-lg-row col-12"
                    >
                        <div
                            class="my-3 d-flex align-items-center rounded-circle justify-content-around"
                        >
                            <vs-input
                                v-model="keyword"
                                border
                                shadow
                                type="text"
                                placeholder="Buscar .."
                                class="mr-2"
                                @click-icon="advanceSearch"
                                @keyup.enter="advanceSearch"
                            >
                                <template #icon> 🔎 </template>
                            </vs-input>
                            <vs-button
                                circle
                                icon
                                floating
                                @click="reloadItems"
                            >
                                <reload-icon />
                            </vs-button>
                        </div>

                        <vs-pagination
                            v-model="currentPage"
                            :length="pagesNumber"
                            @input="listItems()"
                        />
                    </div>
                    <div
                        v-if="imagesList.length"
                        class="w-100 d-flex flex-wrap"
                    >
                        <div
                            v-for="(item, index) in imagesList"
                            :key="index"
                            class="col-6 col-md-4 col-lg-3 col-xl-4 mt-3"
                        >
                            <button
                                class="btn rounded p-0"
                                :class="
                                    selectedImage === item.id
                                        ? `${borderCardDark} border-4`
                                        : 'border'
                                "
                                @click="selectionImage(item.id)"
                            >
                                <img
                                    class="w-100"
                                    loading="lazy"
                                    :src="item.url | image"
                                    :alt="item.alt"
                                />
                                <div
                                    class="max-w-24 position-absolute bg-info d-flex align-items-center rounded text-white container__date max-w-24 pl-2 py-2"
                                >
                                    <div class="d-flex flex-column">
                                        <small>
                                            {{ item.alt ? item.alt : "NA" }}
                                        </small>
                                    </div>
                                    <a
                                        :href="`/app/images/detail/${item.id}`"
                                        target="_blank"
                                        rel="noitemCurrenter"
                                    >
                                        🖊️
                                    </a>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div v-else class="col-11 mx-auto mt-4">
                        <strong>
                            Nuestro sistema NO encuentra resultados. Intenta
                            modificar los filtros de búsqueda.
                        </strong>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import ImageCreator from "@/components/utils/ImageCreator.vue";

export default {
    name: "ImageSelector",
    components: {
        ImageCreator,
        ReloadIcon
    },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => false,
            type: Boolean,
            required: true
        },
        enabledCreate: {
            default: () => false,
            type: Boolean,
            required: false
        },
        defaultFilters: {
            default: () => ({}),
            type: Object,
            required: false
        },
        message: { default: () => "", type: String, required: false }
    },
    emits: ["input", "change"],
    data: () => ({
        selectedImage: 0,
        isOpen: false,
        isOpenImageCreator: false,
        isLoading: false,
        currentPage: 1,
        pagesNumber: 0,
        imagesList: [],
        itemsNumber: 0,
        backup: {
            items: [],
            pagination: { pagesNumber: 1, itemsNumber: 1 }
        },
        keyword: ""
    }),
    computed: {
        ...mapState("images", ["images"]),
        ...mapGetters("control", ["backgroundColor", "borderCardDark"])
    },
    watch: {
        itemCurrent(value) {
            this.setOpen(value);
        },
        keyword(value) {
            if (value) {
                this.imagesList = this.images.filter((item) =>
                    item.alt.includes(value.toLowerCase())
                );
                this.itemsNumber = this.imagesList.length;
                this.pagesNumber = 1;
                this.currentPage = 1;
            } else {
                this.imagesList = [...this.backup.items];
                this.itemsNumber = Number(this.backup.pagination.itemsNumber);
                this.pagesNumber = Number(this.backup.pagination.pagesNumber);
            }
        }
    },
    mounted() {
        this.setOpen(this.itemCurrent);
    },
    methods: {
        ...mapActions("images", ["listImagesPaginated"]),
        async loadMoreImages() {
            this.currentPage = this.currentPage + 1;
            await this.listItems();
        },
        async reloadItems() {
            this.keyword = "";
            this.currentPage = 1;
            await this.listItems(true);
        },
        async advanceSearch() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        async listItems(complete = false) {
            this.isLoading = true;
            try {
                const { items, pagination } = await this.listImagesPaginated({
                    ...this.defaultFilters,
                    page: this.currentPage,
                    keyword: this.keyword,
                    complete
                });
                this.imagesList = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.backup = {
                    items,
                    pagination: {
                        itemsNumber: Number(this.itemsNumber),
                        pagesNumber: Number(this.pagesNumber)
                    }
                };
            } catch (error) {
                this.isOpen = false;
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        setOpen(value) {
            this.isOpen = value;
            if (value) {
                this.listItems(true);
            }
        },
        onClose() {
            this.isOpen = false;
            this.$emit("change", false);
        },
        selectionImage(id) {
            this.selectedImage = id;
        },
        selectionUploadImage(data) {
            this.imagesList.unshift({ ...data, uploaded: false });
            this.selectedImage = data.id;
            this.isOpenImageCreator = false;
        },
        pushNewImage() {
            const image = this.imagesList.find(
                (item) => item.id === this.selectedImage
            );
            this.$emit("input", image || {});
            this.isOpen = false;
            this.$emit("change", false);
        }
    }
};
</script>
